import Vue from 'vue';
import '@fontsource/epilogue/400.css';
import '@fontsource/epilogue/600.css';
import '@fontsource/epilogue/700.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import SnackBar from './modules/SnackBar.js';
import VueGtm from '@gtm-support/vue2-gtm';
// @ts-ignore
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { ampli } from './ampli'

const { VUE_APP_GOOGLE_TAG_MANAGER_ID, VUE_APP_APM_ENDPOINT, NODE_ENV, VUE_APP_AMPLITUDE_SDK_KEY } = process.env;

ampli.load({ client: { apiKey: VUE_APP_AMPLITUDE_SDK_KEY } });

Vue.use(ApmVuePlugin, {
  router,
  captureErrors: true,
  config: {
    serviceName: 'auto-agendamento',
    environment: NODE_ENV,
    serverUrl: VUE_APP_APM_ENDPOINT,
  },
});

if (NODE_ENV !== 'development') {
  Vue.use(VueGtm, {
    id: VUE_APP_GOOGLE_TAG_MANAGER_ID,
    defer: false,
    trackOnNextTick: false,
    vueRouter: router,
  });
}

Vue.use(SnackBar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
