<template>
  <div v-if="visible" class="fixed inset-0 bg-[#f0f4f7] text-white flex justify-center items-center">
    <v-progress-circular :size="40" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingState',
  computed: {
    ...mapGetters({
      LoadingState: 'getLoadingState',
    }),
    visible() {
      return this.LoadingState;
    },
  },
};
</script>
