<template>
    <div
      class="snackbar"
      :class="{
        'snackbar--show': show,
        'snackbar--info': info,
        'snackbar--error': error,
        'snackbar--success': success
      }"
    >
   <v-container>
    <v-row>
      <v-col
        cols="2"
        xs="2"
        sm="1"
        md="1"
        class="align-self-center"
      >
       <v-icon v-if="info" color="white">mdi-alert</v-icon>
       <v-icon v-if="error" color="white">mdi-alert</v-icon>
      </v-col>
      <v-col
        cols="10"
        xs="10"
        sm="6"
        md="8"
        class=""
      >
      {{ message }}
      </v-col>
    </v-row>
  </v-container>

 </div>
</template>

<script>
import EventBus from '../../modules/EventBus.js';

export default {
  name: 'SnackBar',
  data: () => ({
    message: '',
    show: false,
    error: false,
    success: false,
    info: false,
    multiline:true,
    vertical:true,
    interval: null
  }),
  methods: {
    setup(payload) {
      clearInterval(this.interval);

      this.show = true;

      this.message = payload.message;
      this.info = payload.info;
      this.error = payload.error;
      this.success = payload.success;

      this.interval = setInterval(() => (this.show = false), payload.delay || 5000);
    }
  },
  mounted() {
    EventBus.$on('SHOW_SNACKBAR', this.setup);
  }
}
</script>

<style lang="postcss" scoped>

 @media only screen and (min-width: 48em) {
  .snackbar {
    border-radius: .4rem;
    display: flex;
    left: 0;
    margin: 15px auto;
    right: 0;
  }
}

@media only screen and (max-width: 48em) {
  div.snackbar {
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    white-space: pre-line;
    line-height: normal;
    padding: 3px 9px;
    @apply justify-center;
  }
}

.snackbar {
  @apply flex flex-row space-x-4;
  white-space: nowrap;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  align-items: center;
  color: white;
  font-size: 17px;
  height: 2rem;
  justify-content: space-between;
  line-height: 2.2rem;
  opacity: 0;
  padding: 1.8rem 2.4rem;
  position: fixed;
  transition-duration: .5s;
  transition-property: opacity, bottom, left, right, top, width, margin, border-radius;
  transition-timing-function: ease;
  z-index: 9999;

  &--error {
    background-color: #C43F38;
  }

  &--info {
    background-color: #F19C37;
  }

  &--success {
  }

  &--show {
    opacity: 1;
    left: 15px;
    right: inherit;
    top: 120px;
  }
}
</style>
