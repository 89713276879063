import Vue from 'vue';
import VueRouter from 'vue-router';
import { Logpose } from '../modules/LogposeService';
import { get } from 'lodash';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: () => import('@/views/NotFoundView.vue'),
  },
  {
    path: '/agendamento',
    component: () => import('@/views/ScheduleView.vue'),
    beforeEnter: (...args) => handleRequestValidation(...args),
    children: [
      {
        path: '/',
        name: 'resultados-busca',
        component: () => import('@/views/SearchResults.vue'),
      },
      {
        path: '/',
        name: 'confirma-agendamento',
        component: () => import('@/views/SchedulingConfirmation.vue'),
      },
      {
        path: '/',
        name: 'agendamento-finalizado',
        component: () => import('@/views/SchedulingFinished.vue'),
      },
    ],
  },
  {
    path: '/erro-inesperado',
    name: 'erro-inesperado',
    component: () => import('@/views/UnexpectedError.vue'),
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/index.vue'),
    beforeEnter: (...args) => handleRequestValidation(...args),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    beforeEnter: (...args) => handleRequestValidation(...args),
    props: route => ({
      logged: route.params.logged ? JSON.parse(route.params.logged) : route.params.logged,
      vida: route.params.vida ? JSON.parse(route.params.vida) : route.params.vida,
      beneficio: route.params.beneficio ? JSON.parse(route.params.beneficio) : route.params.beneficio,
      token: route.params.token
    })
  },
  {
    path: '/inegibilidade',
    name: 'inegibilidade',
    component: () => import('@/views/IneligibleView.vue'),
  },
];

const handleRequestValidation = async (to, _, next) => {
  const sanitizedPath = sanitizePath(to.path, to.params);
  if (
    (sanitizedPath === '/agendamento' && !to.query.Authorization) ||
    (sanitizedPath === '/home' && !to.params.logged && !to.query?.Authorization)
  ) {
    return next({ path: '/' });
  }

  try {
    if (sanitizedPath === '/home' || sanitizedPath === '/agendamento') {
      if (to.query.Authorization) {
        store.commit('updateLoadingState', true);
        store.commit('updateAuthorizationToken', to.query.Authorization || to.params.token);
      }

      const authorization = to.query.Authorization || to.params.token;
      const { benefit, provider} = await Logpose.validateEligibility(authorization, to.params.life?.id, to.params.benefit);

      store.commit('updateBenefit', benefit);
      store.commit('updateIsAmil', provider.providerElegibility);
    }
  } catch (err) {
    const errorMessage = get(err, 'response.data.message');
    const errorMessages = ['Admin life not found.', 'life not found.', 'Usuário não elegível.'];

    if (errorMessages.includes(errorMessage)) {
      return router.push({ path: '/inegibilidade' });
    }

    return router.push({ path: '/erro-inesperado', query: utm });
  } finally {
    store.commit('updateLoadingState', false);
  }

  if (to.path === '/' && to.query.Authorization) {
    return next({ path: '/home', query: to.query, params: to.params });
  }

  if (!to.query.payload) return next();

  const { payload, utm_source, utm_medium } = to.query;
  const utm = { utm_medium, utm_source };

  try {
    const { token, payload: scheduling } = JSON.parse(window.atob(payload));

    const validationToken = await Logpose.validateToken(token);
    if (!validationToken) {
      return next({ path: '/erro-inesperado', query: utm });
    }

    store.commit('scheduling', scheduling);
    store.commit('user', token);

    return next({ name: 'confirma-agendamento', params: { scheduling }, query: utm });
  } catch (err) {
    return next({ path: '/erro-inesperado', query: utm });
  }
};

const router = new VueRouter({
  mode: 'history',
  routes,
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function sanitizePath(path, params) {
  if (!shouldSanitizePath(path, params)) return path;
  return path.endsWith('/') ? path.replace(/\/$/, '') : path;
}

function shouldSanitizePath(path, params) {
  if (params.logged) return false;
  if (params.date) return false;

  return path !== '/';
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    const { utm_source, utm_medium, Authorization } = from.query;

    if (utm_source || utm_medium) {
      return router.push({
        name: to.name,
        query: { utm_source, utm_medium, Authorization },
        params: to.params,
      });
    }
  }

  return next();
});

export default router;
