import Vue from 'vue';
import Vuex from 'vuex';
import { getCellPhone } from '../modules/CellphoneRules';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authorizationToken: null,
    benefit: {},
    isAmil: false,
    loadingState: false,
    scheduling: {},
    specialitiesList: [],
    psychologistClinicWebID: undefined,
    medicalClinicVisionID: undefined,
    user: {},
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getPsychologistClinicWebID(state) {
      return state.psychologistClinicWebID;
    },
    getMedicalClinicVisionID(state) {
      return state.medicalClinicVisionID;
    },
    getLoadingState(state) {
      return state.loadingState;
    },
    getScheduling(state) {
      return state.scheduling;
    },
    getSpecialtyList(state) {
      const transformedData = {};
      const transformedName = {
        'Hematologia e Hemoterapia': 'Hematologia',
      };
      for (const speciality of state.specialitiesList) {
        const { id, tipoPrestadorId, clinicwebId } = speciality;
        const nome = transformedName[speciality.nome] || speciality.nome;

        transformedData[nome] = { id, tipoPrestadorId, clinicwebId };
      }
      const sortedData = {};
      Object.keys(transformedData)
        .sort()
        .forEach(function (key) {
          sortedData[key] = transformedData[key];
        });
      return sortedData;
    },
    getDestinySpecialties(state) {
      return state.specialitiesList;
    },
    getIsAmil(state) {
      return state.isAmil;
    },
    getBenefit(state) {
      return state.benefit;
    },
    getAuthorizationToken(state) {
      return state.authorizationToken;
    },
  },
  mutations: {
    user(store, payload) {
      if (payload.vida.Telefones && payload.vida.Telefones.length) {
        const { cellphoneCode, cellphoneNumber } = getCellPhone(payload.vida.Telefones);
        payload.vida.numero_telefone = cellphoneNumber;
        payload.vida.codigo_telefone = cellphoneCode;
      }
      delete payload.vida.Telefones;
      store.user = {
        ...payload,
      };
    },
    updatePsychologistClinicWebID(state, payload) {
      state.psychologistClinicWebID = payload;
    },
    updateMedicalClinicVisionID(state, payload) {
      state.medicalClinicVisionID = payload;
    },
    updateUser(store, user) {
      store.user = { ...store.user, ...user };
    },
    scheduling(state, payload) {
      state.scheduling = payload;
    },
    saveSpecialtiesList(state, payload) {
      state.specialitiesList = payload;
    },
    updateIsAmil(state, payload) {
      state.isAmil = payload;
    },
    updateLoadingState(state, payload) {
      state.loadingState = payload;
    },
    updateBenefit(state, payload) {
      state.benefit = payload;
    },
    updateAuthorizationToken(state, payload) {
      state.authorizationToken = payload;
    },
  },
  actions: {
    setPsychologistClinicWebID(context, payload) {
      context.commit('updatePsychologistClinicWebID', payload);
    },
    setMedicalClinicVisionID(context, payload) {
      context.commit('updateMedicalClinicVisionID', payload);
    },
    setCurrentUser(context, payload) {
      context.commit('user', payload);
    },
    updateCurrentUser(context, user) {
      context.commit('updateUser', user);
    },
    setScheduling(context, payload) {
      context.commit('scheduling', payload);
    },
    setSpecialitiesList(context, payload) {
      context.commit('saveSpecialtiesList', payload);
    },
    setIsAmil(context, payload) {
      context.commit('updateIsAmil', payload);
    },
    setLoadingState(context, payload) {
      context.commit('updateLoadingState', payload);
    },
    setBenefit(context, payload) {
      context.commit('updateBenefit', payload);
    },
    setAuthorizationToken(context, payload) {
      context.commit('updateAuthorizationToken', payload);
    },
  },
  modules: {},
});
