import SnackBarComponent from '@/components/shared/SnackBar'

import EventBus from './EventBus'

const SnackBar = {
  install(Vue) {
    Vue.component('SnackBar', SnackBarComponent)
    Vue.prototype.$alert = options => {
      EventBus.$emit('SHOW_SNACKBAR', options)
    }
  }
}

export default SnackBar
