const CELLPHONE_TYPE = 1;

function getCellPhone(cellphones) {
  if (!cellphones?.length) return defaultResponse();

  const mainCellphone = cellphones.find(
    (cellphone) =>
      cellphone.tipo_telefone === CELLPHONE_TYPE && (cellphone.telefone_principal || cellphone.verificado)
  );

  const mainCellPhoneNumber = mainCellphone?.numero;
  if (mainCellPhoneNumber) return splitPhoneCode(mainCellPhoneNumber);

  const cellphone = cellphones.find((phone) => phone.tipo_telefone === 1);
  if (cellphone?.numero) return splitPhoneCode(cellphone.numero);

  return defaultResponse();
}

function splitPhoneCode(cellPhoneNumber) {
  const formatedPhone = cellPhoneNumber.replace(/\D/g, '').replace('0', '');

  if (formatedPhone.length !== 11) defaultResponse();

  return {
    cellphoneCode: formatedPhone.substr(0, 2),
    cellphoneNumber: formatedPhone.substr(2).padStart(9, '9'),
  }
}

function defaultResponse() {
  return {
    cellphoneCode: '',
    cellphoneNumber: '',
  }
}

function transformCellPhones(cellPhones) {
  return cellPhones.map((cellphone) => ({
    id: cellphone.id,
    numero: cellphone.cellphoneNumber,
    telefone_principal: cellphone.mainCellphoneNumber,
    tipo_telefone: cellphone.cellphoneType,
    verificado: cellphone.verified,
    vida_id: cellphone.lifeId,
    whatsapp: cellphone.whatsapp,
  }));
}

module.exports = {
  getCellPhone,
  transformCellPhones,
}
