<template>
  <v-app light>
    <v-main class="bg-[#f0f4f7]">
      <router-view />
      <SnackBar />
      <LoadingState />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { Logpose } from './modules/LogposeService';
import EventBus from './modules/EventBus';
import { getCellPhone, transformCellPhones } from './modules/CellphoneRules';
import jwtEncode from 'jwt-encode';
import LoadingState from './components/LoadingState.vue';

export default {
  components: {
    LoadingState,
  },
  mounted() {
    window.toggleFilters = this.toggleFilters;
    window.initByApp = this.initByApp;
  },
  data() {
    return {
      isStartedByApp: false,
    };
  },
  methods: {
    ...mapActions(['updateCurrentUser']),
    async getLoggedInLifeFromApp(token) {
      try {
        if (token) {
          const life = await Logpose.getCurrentLife(token);
          if (!life) return null;

          return this.transformLife(life);
        }
      } catch (error) {
        window.location.href = '/erro-inesperado';
      }
    },
    transformLife(life) {
      const transformedLife = {
        id: life.id,
        usuario_id: life.userId,
        cpf: life.document,
        data_nascimento: life.birthDate,
        email: life.email,
        estado_civil: life.civilStatus,
        nome: life.fullName,
        key: life.key,
        sexo: life.gender,
        endereco_bairro: life.neighborhood,
        endereco_cep: life.zipcode,
        endereco_cidade: life.city,
        endereco_logradouro: life.street,
        endereco_numero: life.number,
        endereco_uf: life.state,
        endereco_complemento: life.additionalDetails,
      };

      const { cellphoneCode, cellphoneNumber } = getCellPhone(transformCellPhones(life.cellphones));

      transformedLife.codigo_telefone = cellphoneCode;
      transformedLife.numero_telefone = cellphoneNumber;

      return transformedLife;
    },
    toggleFilters() {
      EventBus.$emit('CLOSE_OPEN_FILTER');
    },
    initByApp() {
      this.isStartedByApp = true;
      const header = document.querySelector('.ag-header');
      const filterContainer = document.querySelector('.ag-mobile-filter-container');
      const filter = document.querySelector('.ag-mobile-filter');
      const homeHeader = document.querySelector('.ag-home-header');
      const homeView = document.querySelector('.ag-home-view-content');
      const homeCard = document.querySelector('.ag-card');
      const homeFooter = document.querySelector('.ag-home-footer');
      const homeBtnDisabled = document.querySelector('.ag-home-btn-disabled');
      const enabledBtn = document.querySelector('.ag-home-btn-enabled');
      const homeLabels = document.querySelectorAll('.ag-form > div > span');
      const homeForm = document.querySelector('.ag-form');
      const footer = document.querySelector('.footer');
      const homeBtn = document.querySelector('.ag-home-btn');

      const isHomePage =
        homeView &&
        homeCard &&
        homeFooter &&
        homeBtnDisabled &&
        enabledBtn &&
        homeLabels &&
        homeHeader &&
        homeForm &&
        footer &&
        homeBtn;
      if (isHomePage) {
        homeView.style.backgroundColor = '#F0F4F7';
        homeView.style.borderRadius = '0';
        homeCard.style.color = 'black';

        homeFooter.style.display = 'none';
        footer.style.display = 'none';
        homeBtn.style.marginTop = 'auto';

        homeBtnDisabled.classList.remove('ag-home-btn-disabled');

        enabledBtn.classList.remove('ag-home-btn-enabled');
        enabledBtn.style.backgroundColor = '#78AEEB !important';

        homeLabels.forEach((label) => (label.style.fontWeight = '200'));

        homeHeader.remove();

        homeForm.style.justifyContent = 'unset';
        homeForm.style.gap = '30px';
      }

      header && header.remove();
      filterContainer && filterContainer.remove();

      if (filter) filter.style.marginBottom = '0';
    },
  },
  updated() {
    if (this.isStartedByApp) this.initByApp();
  },
  watch: {
    '$route.query.Authorization': {
      async handler(value) {
        try {
          const tokenFromApp = value;
          if (tokenFromApp) {
            const life = await this.getLoggedInLifeFromApp(tokenFromApp);

            if (life) {
              this.updateCurrentUser({
                token: jwtEncode({ vida: life }, ''),
                vida: life,
                usuario: { id: life.usuario_id },
              });
            } else {
              window.location.href = '/erro-inesperado';
            }
          }
        } catch (error) {
          window.location.href = '/erro-inesperado';
        }
      },
    },
  },
};
</script>

<style lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply w-full h-full;
  @apply select-none overflow-auto;
}

body {
  height: inherit;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #0071eb;
}

.v-application {
  height: inherit;
}

.v-application--wrap {
  min-height: 100% !important;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(238, 238, 240) rgb(185, 185, 183);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: rgb(238, 238, 240);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(238, 238, 240);
  border-radius: 10px;
  border: 1px solid rgb(185, 185, 183);
}
</style>
