const { default: Axios } = require('axios');
const { parseISO, format } = require('date-fns');
const { VUE_APP_LOGPOSE_API_ENDPOINT } = process.env;
const axios = Axios.create({
  baseURL: VUE_APP_LOGPOSE_API_ENDPOINT,
});

axios.interceptors.request.use((config) => {
  if (!config.headers) config.headers = {};
  return config;
});

axios.interceptors.response.use(
  (value) => {
    return value;
  },
  (error) => {
    if (error.response.status === 500) {
      window.location.href = '/erro-inesperado';
      return;
    }
    throw error;
  }
);

const getDoctors = async (specialty, specialtyVision, contractType, page, pageSize) => {
  const response = await axios.get('/doctors', {
    params: { specialty, specialtyVision, page, pageSize, contractType },
  });
  if (response.status === 200) {
    const { data, pages, page, total } = response.data;
    return { doctors: data, pages, page, total };
  }
  return [];
};

const validateEligibility = async (token, lifeId, benefit) => {
  const response = await axios.get('/validateEligibility', {
    params: { token, lifeId, benefit },
  });
  return response.data;
};

const getDoctorTimespan = async (doctorId, companyId, dayPhases, date, specialtyId, range = 5) => {
  const response = await axios.get('/v2/doctors/timespan', {
    params: { doctorId, companyId, dayPhases, date: format(date, "yyyy-MM-dd'T'HH:mm:ss"), specialtyId, range },
  });
  if (response.status === 200) {
    const { data } = response.data;
    return data.map((e) => {
      const [date] = e['data'].split('T');
      e['data'] = parseISO(date);
      return e;
    });
  }
  return [];
};

const createPatient = async (data) => {
  const response = await axios.post('/patients', data);
  if (response.status === 200) {
    return response.data;
  }
  return null;
};

const createSchedule = async (data) => {
  const response = await axios.post('/schedules', data);
  if (response.status === 200) {
    return response.data;
  }
  return null;
};

const createScheduleWithAttachment = async (data, file, fileBuffer, token, specialtyId) => {
  const schedule = await axios.post('/schedules', data);
  if (schedule.status >= 400)
    throw new Error('Não foi possível criar o agendamento. Tente novamente em alguns instantes.');

  const body = {
    agendamentoId: schedule.data.scheduleVisionId,
    vidaId: data.patient.lifeId,
    anexo: { content: fileBuffer.split(',')[1], name: file.name, size: file.size, mimetype: file.type },
    especialidadeId: specialtyId,
  };

  const fileResponse = await axios.post(`/patients/saveAttachment`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (fileResponse.status >= 400) {
    await this.trackApmRequest('saveAttachmentError', fileResponse);
  }
  return schedule.data;
};

const getBenefit = async (lifeId, authorizationToken) => {
  try {
    const response = await axios.get(`/patients/${lifeId}/benefits`, {
      headers: { Authorization: authorizationToken },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const getAgendeSpecialties = async () => {
  try {
    const { data } = await axios.get('/specialties');
    return data.specialities;
  } catch (error) {
    throw new Error('Não foi possível obter as especialidades do Agende.');
  }
};

const getCurrentLife = async (token) => {
  try {
    if (token) {
      const response = await axios.get(`/current?token=${token}`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status !== 200 || !response.data) return null;
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const validateToken = async (token) => {
  try {
    const body = { token };
    const response = await axios.post('/auth', body);
    if (response.status !== 200 || !response.data) return null;
    return response.data;
  } catch (error) {
    return null;
  }
};

const login = async (document, password) => {
  try {
    const body = { document, password };
    const response = await axios.post('/login', body);
    if (response.status !== 200 || !response.data) return response;
    return response.data;
  } catch (error) {
    return error;
  }
};

const checkFeature = async (token, benefit) => {
  const body = { token, benefit };
  try {
    const response = await axios.post(`/check/feature/health_plan`, body);
    if (response.status === 200) return 200;
    return 400;
  } catch (error) {
    return 500;
  }
};

const checkElegibility = async (lifeId, benefitId) => {
  const body = { lifeId, benefitId };
  try {
    const response = await axios.post(`/elegibility`, body);
    if (response.status === 200)
      return {
        status: 200,
        isAmil: response.data.isAmil,
      };
    return 400;
  } catch (error) {
    return 500;
  }
};

const checkCanScheduler = async (lifeId) => {
  try {
    const response = await axios.get(`/patients/${lifeId}/canSchedule`);
    if (response.status === 204) return 204;
    return response.data;
  } catch (error) {
    return null;
  }
};

const getExperiences = async (specialtyId) => {
  try {
    const response = await axios.get(`/experiences?specialty=${specialtyId}`);
    return response.data.experiences;
  } catch (error) {
    throw new Error(error);
  }
};

const Logpose = {
  checkCanScheduler,
  checkElegibility,
  checkFeature,
  createPatient,
  createSchedule,
  createScheduleWithAttachment,
  getAgendeSpecialties,
  getBenefit,
  getCurrentLife,
  getDoctorTimespan,
  getDoctors,
  getExperiences,
  login,
  validateEligibility,
  validateToken,
};

module.exports = { Logpose };
